/* Dashboard services */

import { API } from '../../Services/Api';
// import {log} from '@craco/craco/lib/logger'

const request = new API();

export const getDashboardFiltersOptions = (params) => request.api.get(`/dashboard/filters/`, params);
export const getApiKeyOptions = () => request.api.get(`/dashboard/apikey/`);

export const getDashboardCharts = (params) => {
  if (params?.period_preset) {
    delete params?.period_preset
  }
  // if (params?.entity === 'scores') {
  //   delete params?.search
  //   delete params?.filter
  // }
  return request.api.get(`/dashboard/charts/`, params);
};

export const getDashboardInfo = (params) => {
  if (params?.period_preset) {
    delete params?.period_preset
  }
  return request.api.get(`/dashboard/table-events/`, params);
};

export const getDashboardEventsTableColumns = () => {
  return request.api.get(`/dashboard/table-events/columns/`);
};

export const getDashboardDateRefresh = (params) => {
  return request.api.get(`/dashboard/refresh-date/`);
};

export const getDashboardEventDetailsInfo = (params) => {
  return request.api.get(`/dashboard/table-events/details/`, params);
};

export const getNextDashboardInfo = ({ link }) => {
  console.log({base: process.env.REACT_APP_BASE_API_ROUTE, link, full: process.env.REACT_APP_BASE_API_ROUTE + link})

  const uri = new URL(process.env.REACT_APP_BASE_API_ROUTE + link);
  uri.protocol = 'https://';
  return request.api.get(uri, null);
};

export const getTermsStatus = (data) => {
  return request.api.post(`/auth/toc-accepted`, data);
};

export const getObfuscateInfo = (data) => {
  return request.api.post(`/auth/is-obfuscated`, data);
};
