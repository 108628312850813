import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
//import {push, goBack} from 'connected-react-router'
import {Layout, Card, Spin, Typography, Modal} from 'antd'
//import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
//import AppleSignin from 'react-apple-signin-auth';
//import { useGoogleLogin } from '@react-oauth/google';

// COMPONENTS
import LoginForm from '../../Components/Forms/LoginForm'
import AcceptTermsModalContent from './AcceptTermsModal/AcceptTermsModalContent'

// REDUCER
import AuthActions from './reducer'
import DashboardCreators from '../DashboardPage/reducer'
import Images from '../../Images'

const { Title } = Typography;
function LoginPage(props) {
  const {auth, error, fetching, terms_accepted} = props

  const history = useHistory()

  const [termsUpdated, setTermsUpdated] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false);

  function handleSubmit(data) {
    props.signIn(data.email, data.password)
  }

  /*const responseFacebook = (response) => {
    if (response && response.accessToken) {
      props.socialAuth(response.accessToken, 'facebook');
    }
  };*/

  /*const responseGoogle = useGoogleLogin({
    onSuccess: (response) => {
      if (response && response?.access_token) {
        props.socialAuth(response?.access_token, 'google');
      }
    },
    onFailure: (response) => {
      if (response && response?.access_token) {
        props.socialAuth(response?.access_token, 'google');
      }
    }
  });*/

  /*const responseApple = (response) => {
    if (response && response.authorization) {
      if(response?.user){
        props.socialAuth(response.authorization.id_token, 'apple', response?.user);
      }else{
        props.socialAuth(response.authorization.id_token, 'apple');
      }
    }
  };*/

  useEffect(() => {

    document.title = 'Login - Deduce'

    if (auth.userInfo?.["cognito:username"] && !terms_accepted && !termsUpdated) {
      props.getTermsStatus({username: auth.userInfo?.["cognito:username"]})
      setTermsUpdated(true)
    } else if (auth.userInfo && terms_accepted) {
      props.getObfuscateStatus({username: auth.userInfo?.["cognito:username"]});
      history.push('/dashboard')
    } else if (auth.userInfo?.["cognito:username"] && !terms_accepted && termsUpdated) {
      setVisibleModal(true)
    }
    if (error) props.resetError()
  }, [auth.userInfo, terms_accepted])

  const handleDecline = () => {
    props.logout()
    setVisibleModal(false)
    setTermsUpdated(false)
  };

  const handleAccept = () => {
    const currentDate = new Date();
    props.getTermsStatus({
      username: auth.userInfo?.["cognito:username"],
      toc_accepted: true,
      toc_accepted_at: currentDate.toISOString()
    })
  };

  return (
    <Layout>
      <Spin size="large" spinning={fetching} wrapperClassName="full-screen">
        <div className="login-content">

          <img src={Images.logoWithText} alt="" width={340} height={50}/>

          <Card className="login-card">
            <Title level={2} style={{fontWeight: 700, margin: 0}}> Login</Title>
            <LoginForm
              onSubmit={handleSubmit}
              error={error?.message}
            >
              {/*<div className="social-title">
                <span>Sign In With</span>
                <div className="middle-line"/>
              </div>*/}
              {/*<div className="social-btn-block">
                      <Button
                        onClick={responseGoogle}
                        icon={<Icon type="google_icon" width={22} height={22} role="icon" />}
                      >
                        Google
                      </Button>
                      <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                        scope="public_profile,email"
                        returnScopes={true}
                        textButton
                        cssClass="my-social-btn"
                        callback={responseFacebook}
                        disableMobileRedirect={true}
                        render={(renderProps) => (
                          <Button
                            onClick={renderProps.onClick}
                            icon={<Icon type="fb_icon" width={22} height={22} role="icon" />}
                          >
                            Facebook
                          </Button>
                        )}
                      />

                      <AppleSignin
                        authOptions={{
                          clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
                          scope: 'email name',
                          redirectURI: process.env.REACT_APP_BASE_URI + '/login',
                          state: 'state',
                          nonce: 'nonce',
                          usePopup: true,
                        }}
                        className="apple-auth-btn"
                        noDefaultStyle={false}
                        onSuccess={responseApple}
                        onError={responseApple}
                        skipScript={false}
                        render={(renderProps) => (
                          <Button
                            onClick={renderProps.onClick}
                            icon={<Icon type="apple_icon" width={22} height={22} role="icon" />}
                          >
                            Apple
                          </Button>
                        )}
                      />
                    </div>*/}

              {/*<div className="sign-up-link sign-up-link_12">
                      New to In_Home?{' '}
                      <Link to="/sign-up" onClick={handleClearError}>Get Started</Link>
                    </div>*/}
            </LoginForm>
          </Card>

        </div>
      </Spin>
      <Modal title={<h1>Terms of Service & Privacy Notice</h1>}
            getContainer={() => document?.getElementById('main-layout')}
            centered
            open={visibleModal}
            onCancel={handleDecline}
            footer={null}
            destroyOnClose={true}
            width={650}
            className='terms-modal'
            maskClosable={false}
            // closable={false}
        >
          <Spin spinning={fetching}>
            <AcceptTermsModalContent handleDecline={handleDecline} handleAccept={handleAccept} />
          </Spin>
      </Modal>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.auth.error,
  fetching: state.auth.fetching,
  isMobile: state.nav.isMobile,
  terms_accepted: state.dashboard.terms_accepted,
  is_obfuscated: state.dashboard.is_obfuscated,
})

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
  signIn: (email, password) => dispatch(AuthActions.signInRequest(email, password)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
  socialAuth: (accessToken, type, user) => dispatch(AuthActions.socialAuthRequest(accessToken, type, user)),
  getTermsStatus: (params) => dispatch(DashboardCreators.getTermsStatusRequest(params)),
  getObfuscateStatus: (params) => dispatch(DashboardCreators.getObfuscateStatusRequest(params)),
  logout: () => dispatch(AuthActions.logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
