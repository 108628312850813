import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'
import dayjs from 'dayjs'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  getDashboardFiltersOptionsRequest: ['payload'],
  getDashboardFiltersOptionsSuccess: ['payload'],

  getApiKeyOptionsRequest: null,
  getApiKeyOptionsSuccess: ['payload'],
  // getApiKeyOptionsFailure: null,
  changeApiKeySelected: ['apiKeySelected'],

  getDashboardChartsDataRequest: ['payload'],
  getDashboardChartsDataSuccess: ['payload'],

  getDashboardChartsDataEventsRequest: ['payload'],
  getDashboardChartsDataEventsSuccess: ['payload'],

  getDashboardChartsDataUsersRequest: ['payload'],
  getDashboardChartsDataUsersSuccess: ['payload'],

  getDashboardChartsDataAlertsRequest: ['payload'],
  getDashboardChartsDataAlertsSuccess: ['payload'],

  getDashboardChartsDataScoreRequest: ['payload'],
  getDashboardChartsDataScoreSuccess: ['payload'],

  getEventsTableColumnsRequest: ['payload'],
  getEventsTableColumnsSuccess: ['payload'],
  changeEventsTableColumns: ['payload'],
  setEventsTableColumns: ['payload'],

  getDashboardInfoRequest: ['payload'],
  getDashboardInfoSuccess: ['payload'],

  getTermsStatusRequest: ['payload'],
  getTermsStatusSuccess: ['payload'],

  getObfuscateStatusRequest: ['payload'],
  getObfuscateStatusSuccess: ['payload'],

  getDashboardDateRefreshRequest: ['payload'],
  getDashboardDateRefreshSuccess: ['payload'],

  getNewDashboardInfoRequest: ['payload'], // payload: { link }
  getNewDashboardInfoSuccess: ['payload'], // payload: { data }

  getNextDashboardInfoRequest: ['payload'], // payload: { link }
  getNextDashboardInfoSuccess: ['payload'], // payload: { data }

  getEventDetailsInfoRequest: ['payload'], // payload: { id }
  getEventDetailsInfoSuccess: ['payload'], // payload: { data }
  resetEventDetailsInfo: null,

  dashboardFetchingReset: null, // payload: { errors }

  resetResults: null,

  saveFilters: ['payload'],

  logoutSuccess: null,
})

export const DashboardTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  result: null,
  lastRefreshed: null,

  apiKeyOptionsFetching: false,
  apiKeyOptions: null,
  apiKeySelected: [],

  filtersOptionsFetching: false,
  filtersOptions: null,

  chartDataFetching: false,
  chartDataUsersFetching: false,
  chartDataAlertsFetching: false,
  chartDataScoreFetching: false,
  chartData: {
    events: null,
    users: null,
    alerts: null,
    scores: null,
  },
  chartDataScores: null,
  eventsDataResultaResult: false,

  eventsTableColumnsFetching: false,
  eventsTableColumns: null,

  eventDetailsInfoFetching: false,
  eventDetailsInfo: null,
  eventDetailsInfoRaw: null,

  selectFilter: null,
  terms_accepted: null,
  is_obfuscated: null,
})

/* ------------- Selectors ------------- */

export const DashboardSelectors = {
  // getTermsAccepted: (state) => state.dashboard.terms_accepted,
};
/* ------------- Functions for reducer cases ------------- */

const getTermsStatusRequest = (state, {payload}) => {
  return state.merge({fetching: true})
}
const getTermsStatusSuccess = (state, {payload: data}) => {
  return state.merge({fetching: false, terms_accepted: data.toc_accepted})
}
const getObfuscateStatusRequest = (state, {payload}) => {
  return state.merge({fetching: true})
}
const getObfuscateStatusSuccess = (state, {payload: data}) => {
  return state.merge({fetching: false, is_obfuscated: data.obfuscate})
}

const getDashboardInfoRequest = (state, {payload}) => {
  return state.merge({fetching: true})
}
const getDashboardInfoSuccess = (state, {payload: data}) => {
  return state.merge({
    fetching: false,
    result: {...data}
  })
}

const getEventsTableColumnsRequest = (state, {payload}) => {
  return state.merge({eventsTableColumnsFetching: true})
}

const getEventsTableColumnsSuccess = (state, {payload: data}) => {
  let eventsTableColumns
  if (state.eventsTableColumns) {
    if (state.eventsTableColumns.reduce((prev, {id}) => prev + id, '') === data.reduce((prev, {id}) => prev + id, '')) {
      eventsTableColumns = state.eventsTableColumns
    } else {
      eventsTableColumns = data
    }
  } else {
    eventsTableColumns = data
  }

  return state.merge({
    eventsTableColumnsFetching: false,
    eventsTableColumns
  })
}

const changeEventsTableColumns = (state, {payload: {id, checked}}) => {
  const newColumns = [...state.eventsTableColumns].map(column => {
    if (column.id === id) {
      return ({...column, is_active: checked})
    } else {
      return column
    }
  })

  return state.merge({
    eventsTableColumns: newColumns
  })
}

const setEventsTableColumns = (state, {payload: data}) => {
  return state.merge({
    eventsTableColumns: data
  })
}

const dashboardFetchingReset = (state) =>
  state.merge({
    fetching: false,
    filtersOptionsFetching: false,
    chartDataFetching: false,
    chartDataUsersFetching: false,
    chartDataAlertsFetching: false,
    chartDataScoreFetching: false,
    eventsTableColumnsFetching: false,
    eventDetailsInfoFetching: false,
    refreshDateFetching: false,
    apiKeyOptionsFetching: false,
  })

const getNewDashboardInfoRequest = (state) => state.merge({fetchingProducts: true})

const getNewDashboardInfoSuccess = (state, {payload: {...data}}) => {
  return state
    .merge({
      fetchingProducts: false,
      result: {...state.result, ...data},
    })
}

const getNextDashboardInfoRequest = (state) => state.merge({fetching: true})

const getNextDashboardInfoSuccess = (state, {payload: {...data}}) => {
  const allData = state.result.results.concat(data.results)
  return state
    .merge({
      fetching: false,
      result: {...data, results: allData},
    })
  //.setIn(['result', 'results'], allData)
}

const getDashboardFiltersOptionsRequest = (state, {payload}) => state.merge({filtersOptionsFetching: true})

const getDashboardFiltersOptionsSuccess = (state, {payload: data}) => {
  return state
    .merge({
      filtersOptionsFetching: false,
      filtersOptions: [...data],
    })
}

const fakeData = [
  {
      "key": "dpimxx7d",
      "alerts_enabled": true
  },
  {
      "key": "niwwzkjl",
      "alerts_enabled": true
  },
  {
      "key": "concerto-prod",
      "alerts_enabled": false
  }
]

const getApiKeyOptionsRequest = (state) => state.merge({apiKeyOptionsFetching: true})
const getApiKeyOptionsSuccess = (state, {payload: data}) => {
  // let apiKeyOptions = []
  // if(data?.[0] && typeof (data?.[0]) === 'string') {
  //   apiKeyOptions = data?.map(apiKey => ({value: apiKey, label: apiKey}))
  // }else{
  //   apiKeyOptions = [...data]
  // }
  let apiKeyOptions = data?.map(({key, alerts_enabled, score} )=> ({value: key, label: key, alerts_enabled, score }))
  let apiKeySelected = state.apiKeySelected.length ? state.apiKeySelected : [apiKeyOptions[0].value]

  return state
    .merge({
      apiKeyOptionsFetching: false,
      apiKeyOptions: apiKeyOptions,
      apiKeySelected: apiKeySelected
    })
}
// const getApiKeyOptionsFailure = (state) => state.merge({apiKeyOptionsFetching: false})

const changeApiKeySelected = (state, {apiKeySelected}) => {
  return state
    .merge({apiKeySelected})
}
const getDashboardDateRefreshRequest = (state) => state.merge({refreshDateFetching: true})

const getDashboardDateRefreshSuccess = (state, {payload: data}) => {
  return state
    .merge({
      refreshDateFetching: false,
      lastRefreshed: data?.date ? dayjs(data?.date).format('MM/DD/YYYY hh:mm A') : data?.date,
    })
}

const getDashboardChartsDataRequest = (state) => state.merge({chartDataFetching: true})

const getDashboardChartsDataSuccess = (state, {payload: data}) => {
  return state
    .merge({
      chartDataFetching: false,
      // lastRefreshed: dayjs().format('MM/DD/YYYY hh:mm A'),
      chartData: {...data}
    })
}

const getDashboardChartsDataEventsRequest = (state) => state.merge({chartDataFetching: true})

const getDashboardChartsDataEventsSuccess = (state, {payload: data}) => {
  return state
    .merge({
      chartDataFetching: false,
      // lastRefreshed: dayjs().format('MM/DD/YYYY hh:mm A'),
      chartData: {...state.chartData, events: data.events},
      eventsDataResultaResult: true,
    })
}

const getDashboardChartsDataUsersRequest = (state) => state.merge({chartDataUsersFetching: true})

const getDashboardChartsDataUsersSuccess = (state, {payload: data}) => {
  return state
    .merge({
      chartDataUsersFetching: false,
      chartData: {...state.chartData, users: data.users}
    })
}

const getDashboardChartsDataAlertsRequest = (state) => state.merge({chartDataAlertsFetching: true})

const getDashboardChartsDataAlertsSuccess = (state, {payload: data}) => {
  return state
    .merge({
      chartDataAlertsFetching: false,
      chartData: {...state.chartData, alerts: data.alerts}
    })
}

const getDashboardChartsDataScoreRequest = (state) => state.merge({chartDataScoreFetching: true})

const getDashboardChartsDataScoreSuccess = (state, {payload: data}) => {
  return state
    .merge({
      chartDataScoreFetching: false,
      // chartData: {...state.chartData, scores: data.scores}
      chartDataScores: data.scores
    })
}

const getEventDetailsInfoRequest = (state, {payload}) =>
  state.merge({eventDetailsInfoFetching: true})
const getEventDetailsInfoSuccess = (state, {payload: data}) => {
  if (data?.geo) {
    return state.merge({eventDetailsInfoFetching: false, eventDetailsInfo: data})
  } else {
    return state.merge({eventDetailsInfoFetching: false, eventDetailsInfoRaw: data})
  }
}

const resetEventDetailsInfo = (state, {payload: data}) =>
  state.merge({eventDetailsInfo: null, eventDetailsInfoRaw: null})

const resetResults = (state, {}) =>
  state.merge({
    eventsDataResultaResult: null,
  })

  const saveFilters = (state, {payload: data}) => {
    return state.merge({selectFilter: data})
  }

const logoutSuccess = (state) => INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_DASHBOARD_FILTERS_OPTIONS_REQUEST]: getDashboardFiltersOptionsRequest,
  [Types.GET_DASHBOARD_FILTERS_OPTIONS_SUCCESS]: getDashboardFiltersOptionsSuccess,

  [Types.GET_API_KEY_OPTIONS_REQUEST]: getApiKeyOptionsRequest,
  [Types.GET_API_KEY_OPTIONS_SUCCESS]: getApiKeyOptionsSuccess,
  // [Types.GET_API_KEY_OPTIONS_FAILURE]: getApiKeyOptionsFailure,
  [Types.CHANGE_API_KEY_SELECTED]: changeApiKeySelected,

  [Types.GET_EVENTS_TABLE_COLUMNS_REQUEST]: getEventsTableColumnsRequest,
  [Types.GET_EVENTS_TABLE_COLUMNS_SUCCESS]: getEventsTableColumnsSuccess,
  [Types.SET_EVENTS_TABLE_COLUMNS]: setEventsTableColumns,
  [Types.CHANGE_EVENTS_TABLE_COLUMNS]: changeEventsTableColumns,

  [Types.GET_DASHBOARD_CHARTS_DATA_REQUEST]: getDashboardChartsDataRequest,
  [Types.GET_DASHBOARD_CHARTS_DATA_SUCCESS]: getDashboardChartsDataSuccess,

  [Types.GET_DASHBOARD_CHARTS_DATA_EVENTS_REQUEST]: getDashboardChartsDataEventsRequest,
  [Types.GET_DASHBOARD_CHARTS_DATA_EVENTS_SUCCESS]: getDashboardChartsDataEventsSuccess,

  [Types.GET_DASHBOARD_CHARTS_DATA_USERS_REQUEST]: getDashboardChartsDataUsersRequest,
  [Types.GET_DASHBOARD_CHARTS_DATA_USERS_SUCCESS]: getDashboardChartsDataUsersSuccess,

  [Types.GET_DASHBOARD_CHARTS_DATA_ALERTS_REQUEST]: getDashboardChartsDataAlertsRequest,
  [Types.GET_DASHBOARD_CHARTS_DATA_ALERTS_SUCCESS]: getDashboardChartsDataAlertsSuccess,

  [Types.GET_DASHBOARD_CHARTS_DATA_SCORE_REQUEST]: getDashboardChartsDataScoreRequest,
  [Types.GET_DASHBOARD_CHARTS_DATA_SCORE_SUCCESS]: getDashboardChartsDataScoreSuccess,

  [Types.GET_DASHBOARD_INFO_REQUEST]: getDashboardInfoRequest,
  [Types.GET_DASHBOARD_INFO_SUCCESS]: getDashboardInfoSuccess,

  [Types.GET_TERMS_STATUS_REQUEST]: getTermsStatusRequest,
  [Types.GET_TERMS_STATUS_SUCCESS]: getTermsStatusSuccess,

  [Types.GET_OBFUSCATE_STATUS_REQUEST]: getObfuscateStatusRequest,
  [Types.GET_OBFUSCATE_STATUS_SUCCESS]: getObfuscateStatusSuccess,

  [Types.GET_DASHBOARD_DATE_REFRESH_REQUEST]: getDashboardDateRefreshRequest,
  [Types.GET_DASHBOARD_DATE_REFRESH_SUCCESS]: getDashboardDateRefreshSuccess,

  [Types.GET_NEW_DASHBOARD_INFO_REQUEST]: getNewDashboardInfoRequest,
  [Types.GET_NEW_DASHBOARD_INFO_SUCCESS]: getNewDashboardInfoSuccess,

  [Types.GET_NEXT_DASHBOARD_INFO_REQUEST]: getNextDashboardInfoRequest,
  [Types.GET_NEXT_DASHBOARD_INFO_SUCCESS]: getNextDashboardInfoSuccess,

  [Types.GET_EVENT_DETAILS_INFO_REQUEST]: getEventDetailsInfoRequest,
  [Types.GET_EVENT_DETAILS_INFO_SUCCESS]: getEventDetailsInfoSuccess,
  [Types.RESET_EVENT_DETAILS_INFO]: resetEventDetailsInfo,
  [Types.RESET_RESULTS]: resetResults,

  [Types.DASHBOARD_FETCHING_RESET]: dashboardFetchingReset,

  [Types.SAVE_FILTERS]: saveFilters,

  [Types.LOGOUT_SUCCESS]: logoutSuccess,
})
